import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { profileImageUrls } from '../configs/config';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../hooks/useAuth';
import { Switch, FormControl, FormLabel, Button, Box, Text, VStack, useToast } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useLogger } from '../index';
import { messaging } from '../services/firebaseService';  
import { getToken } from 'firebase/messaging';



const MAX_RETRIES = 3;

const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  const { user, updateUser, logout } = useAuth();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [saveMessage, setSaveMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [loadedImages, setLoadedImages] = useState<Set<string>>(new Set());
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [displayName, setDisplayName] = useState<string>('');
  const [notifyOnWatched, setNotifyOnWatched] = useState(user?.notifyOnWatched ?? false);
  const [isIOSDevice] = useState(/iPad|iPhone|iPod/.test(navigator.userAgent));
  const [isInstallable, setIsInstallable] = useState(false);
  const toast = useToast();
 

  const logger = useLogger();

  useEffect(() => {
    if (user) {
      setDisplayName(user.displayName || '');
      setSelectedImage(user.profileImage);
    }
  }, [user]);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    setIsInstallable(!isStandalone);
  }, []);

  const fetchImageUrl = useCallback(async (path: string, retries = 0): Promise<string> => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, path);
      return await getDownloadURL(imageRef);
    } catch (error) {
      logger.error(`Failed to load image: ${path}, attempt ${retries + 1}`, error);
      if (retries < MAX_RETRIES) {
        return fetchImageUrl(path, retries + 1);
      }
      return '';
    }
  }, []);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        profileImageUrls.images.map(path => fetchImageUrl(path))
      );
      const validUrls = urls.filter(url => url !== '');
      setImageUrls(validUrls);
    };

    fetchImageUrls();
  }, [fetchImageUrl]);

  useEffect(() => {
    if (!initialLoadComplete && imageUrls.length > 0) {
      if (user && user.profileImage) {
        setSelectedImage(user.profileImage);
      } else {
        setSelectedImage(imageUrls[0]);
      }
      setInitialLoadComplete(true);
    }
  }, [user, imageUrls, initialLoadComplete]);

  const handleSave = async () => {
    if (user && (selectedImage !== user.profileImage || displayName !== user.displayName || notifyOnWatched !== user.notifyOnWatched)) {
      setIsLoading(true);
      setError(null);
      const updatedUser = {
        ...user,
        profileImage: selectedImage || '',
        displayName: displayName,
        notifyOnWatched: notifyOnWatched,
      };
      try {
        await updateUser(updatedUser);
        setSaveMessage('Profile updated successfully!');
        setTimeout(() => setSaveMessage(''), 3000);
      } catch (err) {
        logger.error('Failed to update profile:', err);
        setError('Failed to update profile. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleImageSelect = (image: string) => {
    setSelectedImage(image);
  };

  const handleImageLoad = (src: string) => {
    logger.info(`Image loaded successfully: ${src}`);
    setLoadedImages(prev => new Set(prev).add(src));
  };

  const handleImageError = useCallback((e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const src = e.currentTarget.src;
    const currentIndex = imageUrls.indexOf(src);
    if (currentIndex < imageUrls.length - 1) {
      const nextImage = imageUrls[currentIndex + 1];
      logger.info(`Attempting to use next available image: ${nextImage}`);
      e.currentTarget.src = nextImage;
    } else {
      logger.info('No more fallback images available, hiding the image element');
      e.currentTarget.style.display = 'none';
    }
  }, [imageUrls]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      logger.error('Logout failed:', err);
      setError('Failed to logout. Please try again.');
    }
  };

  const renderInstallInstructions = () => {
    if (!isInstallable) return null;

    if (isIOSDevice) {
      return (
        <Box 
          mt={4} 
          p={4} 
          borderRadius="md" 
          border="1px" 
          borderColor="gray.200"
        >
          <VStack align="start" spacing={2}>
            <Text fontWeight="bold">Install App for Notifications</Text>
            <Text>To receive notifications, add this app to your home screen:</Text>
            <VStack align="start" pl={4} spacing={1}>
              <Text>1. Tap the share button <span>⬆️</span></Text>
              <Text>2. Scroll down and tap "Add to Home Screen"</Text>
              <Text>3. Tap "Add"</Text>
            </VStack>
            <Text fontSize="sm" color="gray.600">
              iOS 16.4+ required for notifications
            </Text>
          </VStack>
        </Box>
      );
    }

    return (
      <Box mt={4} p={4} borderRadius="md" border="1px" borderColor="gray.200">
        <Text fontWeight="bold">Enable Notifications</Text>
        <Text>
          Click the notification permission prompt in your browser to enable alerts.
        </Text>
      </Box>
    );
  };

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        if (!messaging) {
          throw new Error('Firebase messaging is not initialized');
        }
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
        });
        
        if (user && token) {
          await updateUser({
            ...user,
            pushToken: token
          });
          toast({
            title: "Notifications enabled",
            description: "You will now receive push notifications",
            status: "success"
          });
        }
      } else {
        toast({
          title: "Permission denied",
          description: "Please enable notifications in your browser settings",
          status: "warning"
        });
      }
    } catch (error) {
      console.error('Error requesting permission:', error);
      toast({
        title: "Error",
        description: "Failed to enable notifications",
        status: "error"
      });
    }
  };

  const renderNotificationSection = () => {
    return (
      <FormControl display="flex" flexDir="column" mb={4}>
        <Box display="flex" alignItems="center">
          <FormLabel htmlFor="notify-watched" mb="0">
            Enable push notifications for reagent due times
          </FormLabel>
          <Switch
            id="notify-watched"
            isChecked={notifyOnWatched}
            onChange={async (e) => {
              const isChecked = e.target.checked;
              setNotifyOnWatched(isChecked);
              
              if (isChecked) {
                try {
                  toast({
                    title: "Requesting notification permission...",
                    status: "info",
                    duration: 3000,
                  });
                  
                  const permission = await Notification.requestPermission();
                  toast({
                    title: `Permission status: ${permission}`,
                    status: permission === "granted" ? "success" : "warning",
                    duration: 5000,
                  });
                  
                  if (permission === 'granted') {
                    if (!messaging) {
                      throw new Error('Firebase messaging is not initialized');
                    }
                    const token = await getToken(messaging, {
                      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
                    });
                    
                    if (user && token) {
                      await updateUser({
                        ...user,
                        pushToken: token,
                        notifyOnWatched: isChecked
                      });
                      toast({
                        title: "Notifications enabled successfully",
                        status: "success",
                        duration: 3000,
                      });
                    }
                  }
                } catch (error) {
                  toast({
                    title: "Error setting up notifications",
                    description: (error as Error).message,
                    status: "error",
                    duration: 5000,
                  });
                  setNotifyOnWatched(false);
                }
              }
            }}
          />
        </Box>
        {notifyOnWatched && !user?.pushToken && (
          <Button
            mt={2}
            colorScheme="blue"
            onClick={requestNotificationPermission}
          >
            Enable Browser Notifications
          </Button>
        )}
        <Text fontSize="sm" color="gray.600" mt={1}>
          You'll receive alerts when:
          • Reagents are due for check-in
          • Reagents become overdue
        </Text>
      </FormControl>
    );
  };

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4">User Profile</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Display Name</label>
        <input
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Profile Image</label>
        <div className="flex space-x-4">
          <div className="w-32 h-32 border-2 border-gray-300 rounded-md overflow-hidden">
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected Profile"
                className="w-full h-full object-cover"
                onError={handleImageError}
                onLoad={() => handleImageLoad(selectedImage)}
              />
            )}
          </div>
          <div className="flex-1 flex flex-wrap gap-2 overflow-y-auto max-h-32">
            {imageUrls.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Profile ${index}`}
                className={`cursor-pointer border-2 w-12 h-12 object-cover ${
                  selectedImage === image ? 'border-blue-500' : 'border-transparent'
                } ${loadedImages.has(image) ? '' : 'opacity-50'}`}
                onClick={() => handleImageSelect(image)}
                onError={handleImageError}
                onLoad={() => handleImageLoad(image)}
              />
            ))}
          </div>
        </div>
      </div>
      <button
        onClick={handleSave}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4"
        disabled={isLoading}
      >
        {isLoading ? 'Saving...' : 'Save'}
      </button>
      {renderNotificationSection()}
      {renderInstallInstructions()}
      <Button
        leftIcon={<FiLogOut />}
        onClick={handleLogout}
        colorScheme="red"
        variant="outline"
        w="full"
      >
        Logout
      </Button>
      {saveMessage && <p className="mt-2 text-green-600">{saveMessage}</p>}
      {error && <p className="mt-2 text-red-600">{error}</p>}
    </div>
  );
};

export default UserProfile;