// reagentUtils.ts

export function formatTimeLeft(timeLeft: number | null | undefined, status: string): string {
  if (timeLeft === null || timeLeft === undefined) return 'N/A';
  if (status === 'Overdue') {
    return `Overdue by ${formatDuration(Math.abs(timeLeft))}`;
  }
  return formatDuration(timeLeft);
}

function formatDuration(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  if (hours > 0) {
    return `${hours}h ${remainingMinutes}m`;
  } else {
    return `${minutes}m`;
  }
}