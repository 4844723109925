import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import { serverTimestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import logger from '../utils/logger';
import { getMessaging, Messaging } from 'firebase/messaging';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const getServerTime = () => serverTimestamp();
const analytics = getAnalytics(app);
const performance = getPerformance(app);

// Initialize messaging only in browser environment
let messaging: Messaging | undefined;
try {
  messaging = getMessaging(app);
  logger.info('Firebase messaging initialized');
} catch (error) {
  logger.warn('Firebase messaging failed to initialize:', error);
}

// Enable offline persistence
enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === 'failed-precondition') {
        logger.log('Multiple tabs open, persistence can only be enabled in one tab at a time.');
    } else if (err.code === 'unimplemented') {
        logger.log('The current browser does not support all of the features required to enable persistence');
    }
});

// If you're using Firebase Emulators, uncomment the following line:
// connectFirestoreEmulator(db, 'localhost', 8080);
export const storage = getStorage(app);

export { app, analytics, db, auth, getServerTime, performance, messaging };
